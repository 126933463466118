import styled from "styled-components";
import '../styles/index.css';

export const Container = styled.div`
  padding: 100px 40px 40px 40px;
  min-width: 300px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: medium;
  font-size: 15px;
  color: #333;
  line-height: 1.2;
`;

export const LinkRef = styled.a`
  margin: 0px;
  color: #000005;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 0.1px #000005 dotted;
  transition: color 0.3 ease-in;
  text-decoration: none;
  &:hover {
    color: #a546b5;
  }
`;

export const Paragraph = styled.div`
  padding-top: 20px;
`;

export const Title = styled.div`
  font-size: 17px;
  margin-bottom: 15px;
`;