import React from 'react';
import styled from "styled-components";
import { LinkRef, Paragraph } from "../styles";

import profileImage from '../styles/img/owen.jfif';

const AboutContainer = styled.section`
  align: center;
  padding-bottom: 90px;
`;

const LinkContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Profile = styled.img`
  width: 18vw;
  height: auto;
  display: inline;
  min-width: 200px;
  float: left;
  margin: 0 40px 1.4vw 0;
  border-radius: 4px;
`;

function About() {
  return (
    <AboutContainer>
      <Profile src={profileImage} alt="Owen" className="profile" />
      <Paragraph>
      Hi! I'm Owen Clayton, a Biomedical Engineering student at the University of Waterloo with a passion for blending technology and healthcare to build the future. 
      From designing prosthetics to diving deep into the world of machine learning and AI, I’m all about creating innovative solutions that 
      push the boundaries of engineering in the medical space.
      </Paragraph>
      <Paragraph>
      Whether I'm coding up algorithms or tinkering with CAD designs, I love finding new ways to apply cutting-edge tech to solve real-world problems. Outside the lab, you might catch me hiking up mountains or eating an assortment of nuts (my favourite nut is a pecan!).
      </Paragraph>
    </AboutContainer>
  );
}

export default About;
