import React from 'react';
import { Title } from "../styles";
import styled from 'styled-components';

const NewsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const NewsDate = styled.span`
  color: #000005;
  padding-top: 1px;
  width: 50px;
  font-weight: bold;
  font-size: 12px;
`;

const NewsDescription = styled.span`
  flex: 1;
`;

function News() {
  const newsItems = [
    { date: '🍌', description: 'A cluster of bananas is called a "hand", while a single banana is called a "finger".'},
    { date: '🍌', description: 'Bananas are naturally slightly radioactive.' },
    { date: '🍌', description: 'Bananas can float in water.' },
    { date: '🍌', description: 'Bananas contain a natural chemical called "Serotonin" which makes people happy.' },
    { date: '🍌', description: 'Bananas are technically berries, but strawberries are not!' },
    { date: '🍌', description: 'The scientific name for banana is "Musa sapientum", which means "fruit of the wise men."' },
    { date: '🍌', description: 'Humans share about 50% of their DNA with bananas.' },
    { date: '🍌', description: 'Over 100 billion bananas are eaten every year around the world!' },
    { date: '🍌', description: 'The banana plant is the largest herbaceous flowering plant.' },
    { date: '🍌', description: 'Bananas were first domesticated in Southeast Asia about 7,000 years ago.' },
    { date: '🍌', description: 'There are over 1,000 different varieties of bananas grown around the world.' }
];

  return (
    <section id="news">
      <Title>Here are some fun facts about bananas 🍌:</Title>
      {newsItems.map((item, index) => (
        <NewsContainer key={index}>
          <NewsDate>{item.date}</NewsDate>
          <NewsDescription>{item.description}</NewsDescription>
        </NewsContainer>
      ))}
    </section>
  );
}

export default News;