import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from "../src/styles";

import HomePage from './components/HomePage';

function App() {
  return (
    <Router>
      <Container>
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </main>
      </Container>
    </Router>
  );
}

export default App;